<!-- .section -->
<section class="section has-background-light">
  <div class="container">
    <div class="columns is-multiline">

      <div class="column is-one-third">
        <div class="notification is-info">
          <h1 class="title is-size-4">Sprout</h1>
          <p class="is-size-5">
            Houseplants keep dying? Let Sprout handle it.
          </p>
            <figure class="media-content">
              <span class="icon">
                <i class="fab fa-lg fa-css3-alt"></i>
              </span>
            </figure>
            <div class="media-content is-info">
              <div class="content">
                <a href="https://devpost.com/software/sprout-mzrabh">
                <figure class="image is-4by3">
                  <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/664/749/datas/medium.gif" style="max-width: 512px;" alt="Placeholder image">
                </figure></a>
                <div class="card-content">
                  <div class="content">
                    <time datetime="2021-09-19">Hack the North Sep 19 2021</time>
                  </div>
                </div>
              </div>
            </div>
        </div>

      </div>

      <div class="column is-one-third">
        <div class="notification is-primary">
          <h1 class="title is-size-4">Sling Puck</h1>
          <p class="is-size-5">
            Sling Puck is classic board game that's come to life in 3D. Play against a bot and try to get all your pucks on the other side!
          </p>

            <figure class="media-left">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-align-justify"></i>
              </span>
            </figure>
            <div class="media-content">
              <div class="content">
                <a href="https://devpost.com/software/sling-puck"><figure class="image is-4by3">
                  <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/329/708/datas/medium.PNG" style="max-width: 512px;" alt="Placeholder image">
                </figure></a>
                <div class="card-content">
                  <div class="content">
                    <time datetime="2021-01-09">Snakes and Hackers Jan 9 2021</time>
                  </div>
                </div>
                
              </div>
            </div>
        </div>

      </div>
      <div class="column is-one-third">
        <div class="notification is-warning">
          <h1 class="title is-size-4">Gesture Controlled Prosthetics for Partial Paralysis</h1>
          <p class="is-size-5">
            This revolutionary design proposal grants stroke survivors with partial paralysis the freedom & autonomy of movement.
          </p>

            <figure class="media-left">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-align-justify"></i>
              </span>
            </figure>
            <div class="media-content">
              <div class="content">
                <a href="https://devpost.com/software/gesture-controlled-prosthetics"><figure class="image is-4by3">
                  <img src="//challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/588/193/datas/gallery.jpg" style="max-width: 512px;" alt="Placeholder image">
                </figure></a>
                <div class="card-content">
                  <div class="content">
                    <time datetime="2018-02-04">ElleHacks Feb 04 2018</time>
                  </div>
                </div>
                
              </div>
            </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- /.section -->


<section class="section">
  <div class="container">
    <div class="columns">
      <div class="card column">
        <div class="card-image notification">
          <figure class="image is-4by3">
            <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/323/926/datas/medium.PNG" style="max-width: 512px;" alt="Placeholder image">
          </figure>
          <div class="card-content">
            <div class="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.
              <a href="#">#css</a> <a href="#">#responsive</a>
              <br>
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        </div>
        
      </div>
      <div class="card column">
        <div class="card-image notification">
          <figure class="image is-4by3">
            <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/588/193/datas/medium.jpeg" style="max-width: 512px;" alt="Placeholder image">
          </figure>
          <div class="card-content">
            <div class="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.
              <a href="#">#css</a> <a href="#">#responsive</a>
              <br>
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</section>

<div class="card projectgrid">

    <div class="card-image first">
      <figure class="image is-4by3">
        <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/664/749/datas/medium.gif" alt="Placeholder image">
      </figure>
      <div class="card-content">
        <div class="content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Phasellus nec iaculis mauris. <a>@bulmaio</a>.
          <a href="#">#css</a> <a href="#">#responsive</a>
          <br>
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
        </div>
      </div>
    </div>

    <div class="card-image second">
      <figure class="image is-4by3">
        <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/329/708/datas/medium.PNG" alt="Placeholder image">
      </figure>
      <div class="card-content">
        <div class="content">
          Sling Puck is classic board game that&#39;s come to life in 3D. Play against a bot and try to get all your pucks on the other side! You can also earn coins and buy awesome skins for your pucks.
          <a href="#">#css</a> <a href="#">#responsive</a>
          <br>
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
        </div>
      </div>
      </div>

      <div class="card-image third">
        <figure class="image is-4by3">
          <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/323/926/datas/medium.PNG" alt="Placeholder image">
        </figure>
        <div class="card-content">
          <div class="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Phasellus nec iaculis mauris. <a>@bulmaio</a>.
            <a href="#">#css</a> <a href="#">#responsive</a>
            <br>
            <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
          </div>
        </div>
        </div>

        <div class="card-image fourth">
          <figure class="image is-4by3">
            <img src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/588/193/datas/medium.jpeg" alt="Placeholder image">
          </figure>
          <div class="card-content">
            <div class="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.
              <a href="#">#css</a> <a href="#">#responsive</a>
              <br>
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        </div>
            
  </div>

  <div><iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6783140218879922176" height="741" width="600" frameborder="0" allowfullscreen="" title="Embedded post"></iframe></div>